.OrderManager {
  padding-top: 50px;
  background-color: white;
  color: black;
  min-height: 100vh;
}

.CardContainer {
  margin-bottom: 20px;
}

.CardTitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.NoOrdersMessage {
  font-style: italic;
  color: #999;
}

.OrderRow {
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.OrderRow:last-child {
  border-bottom: none;
}
