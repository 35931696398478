.OrderRow {
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.OrderRow p {
    margin: 0;
    font-size: 1rem;
}

.OrderPopup {
    margin-top: 10px;
}
