.MaterialManager {
    padding-top: 50px;
    background-color: white;
    color: black;
    min-height: 100vh;
}

.MaterialCard {
    margin-bottom: 20px;
}

.ColorRow {
    cursor: pointer;
    margin-top: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.ColorRow:hover {
    background-color: #e9ecef;
}

.ColorDetails {
    margin-top: 10px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
}

.ColorDetails .form-group {
    margin-bottom: 15px;
}

.ColorDetails .form-label {
    font-weight: bold;
}

.ColorDetails .form-control {
    margin-bottom: 10px;
}

.ColorDetails button {
    margin-top: 20px;
}
