.HomePage {
  padding-top: 50px;
  color: black;
  min-height: 100vh;
}

.order-count {
  margin: 10px;
  padding: 20px;
  border: 1px solid #dee2e6;
  min-width: 150px;
  min-height: 100%;
  text-align: center;
  transition: background-color 0.3s;
  background-color: white;
}

.order-count:hover {
  background-color: #f8f9fa;
}

.order-count h2, .order-count p {
  margin: 0;
}

.order-count h2 {
  margin-bottom: 10px;
}

.order-count p {
  font-size: 1.5em;
  font-weight: bold;
}
