.OrderPopupContainer {
    z-index: 10;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 5%;
    align-items: center;
}

.OrderPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-style: solid;
    border-color: #dee2e6;
    border-width: 0.1em;
    background-color: white;
    width: 80%;
    color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.OrderPopupHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
}

.OrderPopupHeader p {
    margin: 0;
}

.OrderPopupHeader h1 {
    margin: 0;
}

.OrderPopupHeader .ExitPopup {
    background-color: #ccc;
    padding: 10px;
    border-radius: 5px;
}

.OrderPopupHeader .ExitPopup:hover {
    cursor: pointer;
    background-color: #bbb;
}

.GeneralInformation {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.GeneralInformation label {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 45%;
    margin-bottom: 10px;
}

.GeneralInformation p {
    margin: 0;
}

.OrderController {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.OrderUpdater {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.STLOrderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.STLRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.STLDownloadButton {
    align-self: center;
}

.STLInfo {
    align-self: center;
    width: 22%;
}

.LocationPElement {
    overflow-x: scroll;
}

.OrderPopupContainer hr {
    width: 100%;
    border: 0.5px solid #dee2e6;
    margin: 20px 0;
}
